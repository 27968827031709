import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ResEmbeddedYoutube from '../components/ResEmbeddedYoutube'

const items = [
  {
    key: 'video-1',
    lang: 'English',
    title: 'Woman hospitalized with foot infection days after getting pedicure at Noblesville spa.',
    desc: 'Why take the risk with Magnetic Jets? Ask for our 100% Disposable Contégo Jet-Liners.',
    videoLink: 'https://youtu.be/0qurDgBt9u0'
  },
  {
    key: 'video-2',
    lang: 'English',
    title:
      'ABC News goes undercover to reveal what you are really sinking your hands and feet into at some salons.',
    desc: 'Why take the risk with Magnetic Jets? Ask for our 100% Disposable Contégo Jet-Liners.',
    videoLink: 'https://youtu.be/0rbMGM9n1l4'
  },
  {
    key: 'video-3',
    lang: 'English',
    title: 'Contégo Technologies',
    desc: 'Contégo Patented Technologies reform the pedicure sanitation standards',
    videoLink: 'https://www.youtube.com/watch?v=UgweYkPh1jU?rel=0'
  },
  {
    key: 'video-4',
    lang: 'English',
    title: 'Contego Spa Designs Intro to AirJet Liner',
    desc:
      'Contégo Spa Designs introduces the only pedicure spa chair in the industry that prevents foot infections. This is our one-time use disposable AirJet Liner',
    videoLink: 'https://youtu.be/-DDksqVPw1U?rel=0'
  },
  {
    key: 'video-5',
    lang: 'Vietnamese',
    title: 'Contego Spa Designs Giới Thiệu Những Tính Năng Mới Nhất',
    desc:
      'Contégo Spa Designs xin hân hạnh giới thiệu với quý khách hàng những phát minh mới nhất của chúng tôi được bảo hộ bản quyền bởi chính phủ Hoa Kỳ. Những công nghệ mới của Contégo nhằm mang lại sự an toàn vệ sinh cho khách hàng và tiết kiệm thời gian tẩy trùng Magnetic Jet truyền thống.',
    videoLink: 'https://youtu.be/LeEMO3F61vQ?rel=0'
  },
  {
    key: 'video-6',
    lang: 'English',
    title: 'VIP Bench Introduction',
    desc: 'Promo video introduces our VIP Bench',
    videoLink: 'https://youtu.be/WQXeZtFsov0'
  }
]
const VideosPage = () => {
  return (
    <Layout>
      <SEO
        title="Videos"
        description="Learn more about safe pedicure practices, advanced technologies with Contego"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container text-center py-5">
        <h1>VIDEOS</h1>
      </div>
      <div className="container-fluid px-5 py-5">
        <div className="row">
          {items.map(item => {
            return (
              <div className="col-lg-4 my-2" key={item.key}>
                <div className="p-2 shadow rounded">
                  <ResEmbeddedYoutube url={item.videoLink} />
                  <p className="px-2 py-2 rounded bg-secondary text-light d-inline">
                    <strong>{item.lang}</strong>
                  </p>
                  <div className="py-4 pl-2">
                    <h2 className="mt-2">{item.title}</h2>
                    <p>{item.desc}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default VideosPage
